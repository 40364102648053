import { FaReact, FaHtml5, FaCss3, FaWordpress, FaPhp   } from "react-icons/fa";
import { SiJavascript,  } from "react-icons/si";
import Project1 from "../assets/project4.png";
import Project2 from "../assets/Project2.jpg";
import Project3 from "../assets/project3.png";
import Project4 from "../assets/project5.png";
import Project5 from "../assets/project6.png";
import Project6 from "../assets/project7.png";
import Project7 from "../assets/project8.png";
export const resumeLink = "https://drive.google.com/file/d/1XbjXR7CyeIv7aJRIWxpxjKxsMAkwYcd8/view?usp=sharing"
export const skills = [
  {
    id: 0,
    tech: "React JS",
    icon: <FaReact />,
  },
  {
    id: 1,
    tech: "HTML",
    icon: <FaHtml5 />,
  },
  {
    id: 2,
    tech: "CSS",
    icon: <FaCss3 />,
  },
  {
    id: 3,
    tech: "JavaScript",
    icon: <SiJavascript />,
  },
  {
    id: 4,
    tech: "PhP",
    icon: <FaPhp  />,
  },
  {
    id: 5,
    tech: "Wordpress",
    icon: <FaWordpress  />,
  },
];

export const projects = [
  {
    id: 0,
    project_name: "Konzo Vibez Platform",
    project_desc: "A full-stack community portal for college students.",
    features:
      " A website dedicated to showcasing the music industry in Western Uganda. This platform provides local artists with an online presence, promoting their work to a wider audience and helping them gain the recognition they deserve.",
    tech_stack: ["React JS", "Styled-Components", "Bootstrap", "Firebase", "Material UI"],
    project_img: Project3,
    project_url: "https://konzovibez.biz",
    reverse: false,
  },

  {
    id: 1,
    project_name: "Konzo Mobi App",
    project_desc:"A video streaming platform which utilizes YouTube API",
    features:"A mobile application that streams media outlets in Western Uganda. This app enhances access to local news, music, and cultural content, ensuring that the region's diverse voices are heard and celebrated.",
    tech_stack: ["React Native", "Redux Toolkit", "Tailwind", "Firebase"],
    project_img: Project2,
    project_url: "https://play.google.com/store/apps/details?id=konzomobi.app&hl=en_US",
    github_link: "https://github.com/mikasa-404/yt-clone",
    reverse: true,
  },
  {
    id: 2,
    project_name: "Organization Website",
    project_desc:"Developed a food ordering application using React",
    features:"Rural Women and Orphans Empowerment Initiative (RWOEI) ",
    tech_stack: ["NextJS 14", "Materialize", "Tailwind"],
    project_img: Project1,
    project_url: "https://rwoei.org",
    github_link: "https://github.com/mikasa-404/food-ordering-app",
    reverse: false,
  },
  {
    id: 2,
    project_name: "Snow Tabloid",
    project_desc:"Developed a food ordering application using React",
    features:"now Tabloid stands as a beacon of information and entertainment, delivering captivating content that resonates with the diverse audience of the region and beyond. With a commitment to excellence, Snow Tabloid continues to be a dynamic force, weaving together stories that reflect the essence of its community and beyond. Explore the latest, stay informed, and indulge in the cultural tapestry of Western Uganda with Snow Tabloid. ",
    tech_stack: ["Wordpress",],
    project_img: Project4,
    project_url: "https://snowtabloid.com",
    github_link: "https://github.com/mikasa-404/food-ordering-app",
    reverse: false,
  },
  {
    id: 2,
    project_name: "Organization Website",
    project_desc:"Developed a food ordering application using React",
    features:"RAPCD is a Non Governmental Organization founded in 2007 by parents of children with disabilities. It is the Umbrella organization through which District Unions of Persons with Disabilities and the District Community Development Departments networks with grass root children with disabilities. ",
    tech_stack: ["React JS", "Font Awesome", "Material UI"],
    project_img: Project5,
    project_url: "https://rapcd.org",
    github_link: "https://github.com/mikasa-404/food-ordering-app",
    reverse: false,
  },
  {
    id: 2,
    project_name: "Artist Website",
    project_desc:"Developed a food ordering application using React",
    features:"Lydia Ugochi is an artist, vocalist, and Mugisu from Mbale, currently pursuing her music career in Kasese, Western Uganda. ",
    tech_stack: ["React JS", "Redux", "Styled-Components", "Axios", "GSAP"],
    project_img: Project6,
    project_url: "https://lydiaugochi.com",
    github_link: "https://github.com/mikasa-404/food-ordering-app",
    reverse: false,
  },
  {
    id: 2,
    project_name: "Organization Website",
    project_desc:"Developed a food ordering application using ",
    features:"The Voice of The Youth with Disabilities (VYD), a dynamic and vibrant Community Based Organization nestled in the heart of Kasese Municipality, Western Uganda.",
    tech_stack: ["React JS", "Font Awesome", "Material UI"],
    project_img: Project5,
    project_url: "https://vyd-ug.org",
    github_link: "https://github.com/mikasa-404/food-ordering-app",
    reverse: false,
  },
];

export const navLinks = [
  {
    id: 0,
    name: "Home",
    href: "Home",
  },
  {
    id: 1,
    name: "My Skills",
    href: "Skills",
  },
  {
    id: 2,
    name: "My Experience ",
    href: "Experience",
  },
  {
    id: 3,
    name: "My Projects ",
    href: "Projects",
  },
  {
    id: 4,
    name: "Contact",
    href: "Contact",
  },
];
