import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../sanityClient";
import { Heading, ParaText } from '../styles/Global.styled';
import BlockContent from '@sanity/block-content-to-react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'; // Import Helmet

// Styled components for the blog post
const BlogPostContainer = styled.div`
  color: white; /* Set the content color to white */
  padding: 20px; /* Add some padding for better readability */
`;

const Title = styled(Heading)`
  margin-top: 50px; /* Set the title 50px from the top */
  cursor: pointer; /* Make the title cursor a pointer */
`;

const MainImage = styled.img`
  width: 100%; /* Ensure the main image is responsive */
  max-width: 600px; /* Limit the size of the main image */
  height: auto;
  margin-top: 20px; /* Add some space above the image */
  border-radius: 10px; /* Optional: add some border radius for styling */
`;

// Styled component for block content with white text
const StyledBlockContent = styled(BlockContent)`
  color: white;

  p {
    color: white; /* Ensure paragraphs are white */
  }

  a {
    color: white; /* Ensure links are white */
  }

  h1, h2, h3, h4, h5, h6 {
    color: white; /* Ensure headings are white */
  }

  ul, ol {
    color: white; /* Ensure list items are white */
  }

  blockquote {
    color: white; /* Ensure blockquotes are white */
  }

  img {
    max-width: 100%; /* Ensure images within BlockContent are responsive */
    height: auto;
    border-radius: 10px; /* Optional: add some border radius for styling */
    margin: 20px 0; /* Add some space around images */
  }
`;

const BlogPost = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    // Function to scroll to the top of the page on first render
    window.scrollTo(0, 0);

    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          description,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          body,
          _createdAt
        }`,
        { slug }
      )
      .then((data) => {
        setPost(data[0]);
      })
      .catch((error) => {
        console.error("Error fetching post:", error.message);
      });
  }, [slug]);

  if (!post) return <div>Loading...</div>;

  return (
    <BlogPostContainer>
      <Helmet>
        <title>{post.title}</title>
        <meta name="description" content={post.description} />
        {post.mainImage && (
          <meta property="og:image" content={post.mainImage.asset.url} />
        )}
      </Helmet>
      {/* Make the title a link to the home page */}
      <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
        <Title as="h2" size="h2">{post.title}</Title>
      </Link>
      {post.mainImage && <MainImage src={post.mainImage.asset.url} alt={post.title} />}
      <ParaText>{post.description}</ParaText>
      {/* <ParaText>{new Date(post._createdAt).toLocaleDateString()}</ParaText> */}
      <StyledBlockContent
        blocks={post.body}
        projectId={sanityClient.config().projectId} // Pass the projectId
        dataset={sanityClient.config().dataset} // Pass the dataset
      />
    </BlogPostContainer>
  );
};

export default BlogPost;
