import React, { useEffect, useState } from "react";
import sanityClient from "../sanityClient";
import PostCard from "./PostCard";
import {
  Heading,
  BlueText,
} from "../styles/Global.styled";
import { SkillsCardContainer } from "../styles/Cards.styled";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post"]{
          title,
          description,
          mainImage{
            asset->{
              _id,
              url
            }
          },
          slug,
          _createdAt
        }`
      )
      .then((data) => setPosts(data))
      .catch((error) => console.error("Error fetching posts:", error.message));
  }, []);

  return (
    <SkillsCardContainer>
       <Heading as="h2" size="h2" top="0.5rem">
            My <BlueText>Diary</BlueText>
          </Heading>
      {posts.map((post) => (
        <PostCard
          key={post.slug.current}
          title={post.title}
          description={post.description}
          image={post.mainImage?.asset?.url}
          timestamp={post._createdAt}
          slug={post.slug.current} // Pass slug as a string
        />
      ))}
    </SkillsCardContainer>
  );
};

export default Posts;
