import React from "react";
import {
  FlexContainer,
  PaddingContainer,
  Heading,
  IconContainer,
  ParaText,
  BlueText,
  FadeImage,
} from "../styles/Global.styled";
import { skills } from "../utils/Data";
import { SkillsCard, SkillsCardContainer } from "../styles/MySkills.styled";
import { motion } from "framer-motion";
import { fadeInLeftVariant, fadeInRightVariant } from "../utils/Variants";
import fadeimage from "../assets/right.png"

const MySkills = () => {
  return (
    <PaddingContainer
      id="Skills"
      top="10%"
      bottom="10%"
      responsiveLeft="1rem"
      responsiveRight="1rem"
    >
      <FadeImage src={fadeimage} right="0" width="inherit" />
      <FlexContainer
        fullWidthChild
        responsiveFlex
        responsiveDirection="column-reverse"
      >
        {/* left-section */}
        <SkillsCardContainer
          as={motion.div}
          variants={fadeInLeftVariant}
          initial="hidden"
          whileInView="visible"
        >
          {skills.map((skill) => (
            <SkillsCard key={skill.id}>
              <IconContainer size="5rem" color="blue">
                {skill.icon}
              </IconContainer>
              <Heading as="h4" size="h4">
                {skill.tech}
              </Heading>
            </SkillsCard>
          ))}
        </SkillsCardContainer>
        {/* right section */}
        <motion.div
          variants={fadeInRightVariant}
          initial="hidden"
          whileInView="visible"
        >
          <Heading as="h4" size="h4">
            MY SKILLS
          </Heading>
          <Heading as="h2" size="h2" top="0.5rem">
            What <BlueText>I can do</BlueText>
          </Heading>
          <ParaText top="2rem" bottom="1.5rem">
          As a developer, I have extensive experience in web technologies. I am proficient in HTML, CSS, JavaScript, PHP, and WordPress, as well as frameworks like React.js, Next.js, and React Native for Android app development. I am skilled in using styling libraries such as Tailwind CSS, Chakra UI, Material UI, and styled-components to create visually appealing and responsive user interfaces. Additionally, I have experience with Firebase, Supabase, Sanity, and GraphQL.
          </ParaText>
          <ParaText>
          Apart from web development, I have a strong interest in problem-solving, critical thinking, and developing managerial skills. I thrive on tackling complex challenges, making informed decisions, and leading teams to successful outcomes.
          </ParaText>
        </motion.div>
      </FlexContainer>
    </PaddingContainer>
  );
};

export default MySkills;
